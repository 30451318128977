<template>
  <div class="modalComponent">
    <div
      v-show="isModalOpen"
      class="modal"
      id="errorModal"
      :style="isModalOpen == false ? 'top: 50%; left: 50%;' : ''"
    >
      <div class="modal_header">
        <h3 class="title">{{ modal_title }}</h3>
        <button class="modal_close" @click="CloseModal"></button>
      </div>
      <div class="modal_body" v-html="modal_content">
        <!-- {{ modal_content }} -->
      </div>
      <div class="modal_footer">
        <button @click="CloseModal">{{ no }}</button>
        <button @click="BtnOkClick">{{ yes }}</button>
      </div>
    </div>
    <div v-if="isModalOpen" class="modal_layer" @click="CloseModal"></div>
  </div>
</template>

<script>
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [DRAG_MODAL_MIXIN],
  props: {
    modal_title: {
      type: String,
      required: true,
    },
    modal_content: {
      type: String,
      required: true,
    },
    isModalOpen: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: false,
    },
    yes: {
      default: '확인',
    },
    no: {
      default: '취소',
    },
  },
  methods: {
    BtnOkClick() {
      // this.$emit('OnYesClick');
      this.$emit('OnYesClick', this.index, this);
    },
    CloseModal() {
      this.$emit('onclose');
    },
  },
};
</script>

<style scoped></style>
