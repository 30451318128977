import ModalMixin from '@/mixins/modal';
import { makeComma } from '@/utils/filters';
export default {
  data() {
    return {
      discountFromTotal: false,
      total_dis: 0,
    };
  },
  mixins: [ModalMixin],
  methods: {
    mx_makeComma(value) {
      if (value == undefined) return '';
      if (value.length == 0) {
        return '0';
      }
      let rightDot = '';
      value = String(value).replace(/,/gi, '');
      if (value.includes('.') && value.split('.')[1].length > 0) {
        rightDot = '.' + value.split('.')[1];
        value = value.split('.')[0];
      } else {
        value = Number(String(value).replace(/,/gi, ''));
        if (value == 0) {
          return '0';
        }
      }
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + rightDot;
    },
    makeQuantity(e, target) {
      try {
        let value = null;
        if (e.data == null) {
          value = e.target.value.replace(/,/gi, '');
        } else {
          value = e.target.value.replace(/,/gi, '');
          // value = String(target.cost).replace(/,/gi, '') + e.data;
        }
        if (value.length == 0) {
          target.quantity = '0';
          return;
        }
        value = Number(value);
        if (value == 0) {
          target.quantity = '0';
        }
        target.quantity = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } catch (e) {
        console.log(e);
      }
    },
    makeTotalValue(e, target) {
      try {
        let value = null;
        if (e.data == null) {
          value = e.target.value.replace(/,/gi, '');
        } else {
          value = e.target.value.replace(/,/gi, '');
          // value = String(target.cost).replace(/,/gi, '') + e.data;
        }
        if (value.length == 0) {
          target.total_value = '0';
          return;
        }
        value = Number(value);
        if (value == 0) {
          target.total_value = '0';
        }
        target.total_value = String(value).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ',',
        );
      } catch (e) {
        console.log(e);
      }
    },
    makeCommaString(e) {
      let ret = String(Number(String(e.target.value).replace(/,/gi, '')));
      e.target.value = makeComma(ret);
    },
    reCalDiscount(e, targetArray, vat) {
      let value = $('#total_discount')[0].value.replace(/,/gi, '');
      if (value.length == 0) {
        value = 0;
      }
      let changed_discount = this.$makeNumber(value);
      for (let i = targetArray.length - 1; i >= 0; i--) {
        const total_cost = Number(
          String(
            this.calSupplyTax(
              vat,
              this.getProductInfo(targetArray[i].product_id).tax,
              this.$makeNumber(targetArray[i].quantity) *
                this.getBoxQuantity(targetArray[i].box_unit_id),
              this.$makeNumber(targetArray[i].cost),
            ).total,
          ),
        );
        if (changed_discount == 0) {
          targetArray[i].discount = 0;
        } else {
          if (total_cost <= changed_discount) {
            if (i != 0) {
              targetArray[i].discount = makeComma(total_cost);
              changed_discount -= total_cost;
            } else {
              targetArray[i].discount = makeComma(changed_discount);
            }
          } else {
            targetArray[i].discount = makeComma(changed_discount);
            changed_discount = 0;
          }
        }
      }
    },
    makeCost(e, target) {
      let value = null;
      if (e.data == null) {
        value = e.target.value.replace(/,/gi, '');
      } else {
        value = e.target.value.replace(/,/gi, '');
        // value = String(target.cost).replace(/,/gi, '') + e.data;
      }
      if (value.length == 0) {
        target.cost = '0';
        return;
      }
      value = Number(value);
      if (value == 0) {
        target.cost = '0';
      }
      target.cost = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    makeDiscount(e, target) {
      let value = null;
      if (e.data == null) {
        value = e.target.value.replace(/,/gi, '');
      } else {
        value = e.target.value.replace(/,/gi, '');
        // value = String(target.cost).replace(/,/gi, '') + e.data;
      }
      if (value.length == 0) {
        target.discount = '0';
        return;
      }
      value = Number(value);
      if (value == 0) {
        target.discount = '0';
      }
      target.discount = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    onlyNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return false;
      }
    },
    onlyNumberDot(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode == 46 &&
        (evt.target.value.includes('.') || evt.target.value.length == 0)
      ) {
        evt.preventDefault();
      }
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    calSupplyTax(vat, tax, num, cos) {
      if (vat == undefined || tax == undefined) {
        this.openOneButtonModal(
          '금액 계산 오류',
          '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
        );
        return { supply: 0, tax: 0, total: 0 };
      }
      const quantity = Number(String(num).replace(/,/gi, ''));
      const cost = Number(String(cos).replace(/,/gi, ''));
      if (tax == true && vat != 3) {
        if (vat == 1) {
          const supply = Math.round(quantity * cost);
          return {
            supply: supply,
            tax: Math.floor(supply * 0.1),
            total: supply + Math.floor(supply * 0.1),
          };
        } else if (vat == 2) {
          const total = Math.round(quantity * cost);
          return {
            supply: Math.round(total / 1.1),
            tax: total - Math.round(total / 1.1),
            total: total,
          };
        } else {
          this.openOneButtonModal(
            '금액 계산 오류',
            '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
          );
          return { supply: 0, tax: 0, total: 0 };
        }
      } else if (tax == false || vat == 3) {
        return {
          supply: Math.round(quantity * cost),
          tax: 0,
          total: Math.round(quantity * cost),
        };
      } else {
        this.openOneButtonModal(
          '금액 계산 오류',
          '선택한 거래처의 부가세 구분값 또는 선택한 품목의 과세여부 값이 정의되지 않았습니다.',
        );
        return { supply: 0, tax: 0, total: 0 };
      }
    },
    calTotalPrice(targetArray, vat, isUseNewTaxId) {
      let non_tax_total_cost = 0;
      let tax_total_cost = 0;
      let total_cost = 0;
      let total_discount = 0;
      let sales_cost = 0;
      targetArray.forEach(el => {
        if (el.cost == undefined || el.quantity == undefined) return null;
        if (isUseNewTaxId) {
          if (this.getProductInfo(el.product_id).tax == 1) {
            tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                this.getProductInfo(el.product_id).tax,
                this.$makeNumber(el.quantity) *
                  this.getBoxQuantity(el.box_unit_id),
                this.$makeNumber(el.cost),
              ).total,
            );
          } else {
            non_tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                this.getProductInfo(el.product_id).tax,
                this.$makeNumber(el.quantity) *
                  this.getBoxQuantity(el.box_unit_id),
                this.$makeNumber(el.cost),
              ).total,
            );
          }
        } else {
          if (el.tax_id == 1) {
            tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                el.tax_id,
                this.$makeNumber(el.quantity) *
                  this.getBoxQuantity(el.box_unit_id),
                this.$makeNumber(el.cost),
              ).total,
            );
          } else {
            non_tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                el.tax_id,
                this.$makeNumber(el.quantity) *
                  this.getBoxQuantity(el.box_unit_id),
                this.$makeNumber(el.cost),
              ).total,
            );
          }
        }
        total_discount += this.$makeNumber(el.discount);
      });
      total_cost = non_tax_total_cost + tax_total_cost;
      sales_cost = total_cost - total_discount;
      this.total_dis = makeComma(total_discount);
      return {
        non_tax_total_cost: makeComma(non_tax_total_cost),
        tax_total_cost: makeComma(tax_total_cost),
        total_cost: makeComma(total_cost),
        total_discount: makeComma(total_discount),
        sales_cost: makeComma(sales_cost),
      };
    },
    cnsCalTotalPrice(targetArray, vat, isUseNewTaxId) {
      let non_tax_total_cost = 0;
      let tax_total_cost = 0;
      let total_cost = 0;
      let total_discount = 0;
      let sales_cost = 0;
      targetArray.forEach(el => {
        if (el.cost == undefined || el.quantity == undefined) return null;
        if (isUseNewTaxId) {
          if (this.getProductInfo(el.product_id).tax == 1) {
            tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                this.getProductInfo(el.product_id).tax,
                this.$makeNumber(el.quantity),
                this.$makeNumber(el.cost),
              ).total,
            );
          } else {
            non_tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                this.getProductInfo(el.product_id).tax,
                this.$makeNumber(el.quantity),
                this.$makeNumber(el.cost),
              ).total,
            );
          }
        } else {
          if (el.tax_id == 1) {
            tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                el.tax_id,
                this.$makeNumber(el.quantity),
                this.$makeNumber(el.cost),
              ).total,
            );
          } else {
            non_tax_total_cost += Number(
              this.calSupplyTax(
                vat,
                el.tax_id,
                this.$makeNumber(el.quantity),
                this.$makeNumber(el.cost),
              ).total,
            );
          }
        }
        total_discount += this.$makeNumber(el.discount);
      });
      total_cost = non_tax_total_cost + tax_total_cost;
      sales_cost = total_cost - total_discount;
      this.total_dis = makeComma(total_discount);
      return {
        non_tax_total_cost: makeComma(non_tax_total_cost),
        tax_total_cost: makeComma(tax_total_cost),
        total_cost: makeComma(total_cost),
        total_discount: makeComma(total_discount),
        sales_cost: makeComma(sales_cost),
      };
    },
  },
};
