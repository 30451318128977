<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div>
          <span>조회기간</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="start_date"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="end_date"
                @change="setEndDate($event)"
              />
            </div>
          </div>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="납품처 검색"
            @input="typingCompany($event)"
            :value="companySearchText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="제품명 검색"
            @input="typing($event)"
            :value="searchText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
        <div class="input_search">
          <input
            type="text"
            placeholder="규격명 검색"
            @input="typingStandard($event)"
            :value="standardText"
          />
          <button>
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
      <h6>조회수 : {{ returnableList.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl product_return_form">
          <colgroup>
            <col v-for="(n, index) in 7" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>납품처</th>
              <th>제품명</th>
              <th>규격</th>
              <th>납품수량(단위)</th>
              <th>단가</th>
              <!-- <th>할인액</th> -->
              <th>매출액</th>
              <th>출고일시</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in returnableList"
              :key="item.id + item.product_ident"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index }"
            >
              <td class="text_left">
                {{ findInfoFromId(companys, item.company_id).name }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, item.product_id).name }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, item.product_id).standard }}
              </td>
              <td class="text_right">
                {{ item.quantity | makeComma
                }}{{ `(${findInfoFromId(units, item.unit_id).name})` }}
              </td>
              <td class="text_right">&#8361; {{ item.cost | makeComma }}</td>
              <!-- <td class="text_right">
                &#8361; {{ item.discount | makeComma }}
              </td> -->
              <td class="text_right">
                &#8361;
                {{
                  mx_makeComma(
                    calSupplyTax(
                      item.vat_id,
                      item.tax_id,
                      $makeNumber(item.quantity),
                      $makeNumber(item.cost),
                    ).total,
                  )
                }}
              </td>
              <td>{{ item.create_time | formatDateNoHours }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="form" @click.prevent>
        <form>
          <div class="input_text">
            <label>납품처명</label>
            <input
              type="text"
              placeholder="납품처"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(companys, managementData.company_id).name
              "
            />
          </div>
          <div class="input_text">
            <label class="require">제품명</label>
            <input
              type="text"
              placeholder="제품"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(products, managementData.product_id).name
              "
            />
          </div>
          <div class="input_text">
            <label class="require">규격</label>
            <input
              type="text"
              placeholder="규격"
              readonly
              :value="
                managementData == null
                  ? ''
                  : findInfoFromId(products, managementData.product_id).standard
              "
            />
          </div>
          <div class="input_text">
            <label>반품수량</label>
            <input
              type="text"
              placeholder=""
              :value="
                managementData == null
                  ? ''
                  : mx_makeComma(managementData.quantity)
              "
              inputmode="decimal"
              @input="$inputNumber($event, managementData, 'quantity')"
              :readonly="managementData == null"
            />
            <label>{{
              managementData == null
                ? ''
                : findInfoFromId(units, managementData.unit_id).name
            }}</label>
          </div>
          <div class="input_text">
            <label>반환 단가</label>
            <input
              type="text"
              placeholder="30"
              readonly
              :value="
                managementData == null
                  ? ''
                  : '₩ ' + mx_makeComma(managementData.cost)
              "
            />
            <!-- inputmode="decimal"
              @input="$inputNumberInt($event, managementData, 'cost')" -->
          </div>
          <div class="input_text">
            <label>공급가액</label>
            <input
              type="text"
              placeholder="3,333"
              readonly
              :value="
                managementData == null
                  ? ''
                  : '₩ ' +
                    mx_makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        managementData.quantity,
                        managementData.cost,
                      ).supply,
                    )
              "
            />
          </div>
          <div class="input_text">
            <label>부가세액</label>
            <input
              type="text"
              placeholder="3,333"
              readonly
              :value="
                managementData == null
                  ? ''
                  : '₩ ' +
                    mx_makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        managementData.quantity,
                        managementData.cost,
                      ).tax,
                    )
              "
            />
          </div>
          <!-- <div class="input_text">
            <label>할인액 </label>
            <input
              type="text"
              placeholder="3,333"
              readonly
              :value="
                managementData == null
                  ? ''
                  : '₩ ' + mx_makeComma(managementData.discount)
              "
            />
          </div> -->
          <div class="input_text">
            <label>반환액</label>
            <input
              type="text"
              placeholder="3,333"
              readonly
              :value="
                managementData == null
                  ? ''
                  : '₩ ' +
                    mx_makeComma(
                      calSupplyTax(
                        managementData.vat_id,
                        managementData.tax_id,
                        managementData.quantity,
                        managementData.cost,
                      ).total,
                    )
              "
            />
          </div>
          <div class="input_text">
            <label>반품사유</label>
            <input
              type="text"
              :readonly="managementData == null"
              :value="managementData == null ? '' : managementData.detail"
              @input="
                $event => {
                  managementData.detail = $event.target.value;
                }
              "
            />
          </div>
          <button
            class="btn_sub2"
            @click="submitForm()"
            v-if="isPermissionOn"
            :disabled="selectedIndex == -1"
          >
            반품 진행
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import FAVORITE_MIXIN from '@/mixins/favorite';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import PRODUCT_PRICE_MIXIN from '@/mixins/price';
import { yyyymmdd } from '@/utils/func';
// import { makeComma } from '@/utils/filters';

export default {
  mixins: [
    FETCH_MIXIN,
    MODAL_MIXIN,
    SPINNER_MIXIN,
    PRODUCT_PRICE_MIXIN,
    FAVORITE_MIXIN,
  ],
  data() {
    return {
      standardText: '',
    };
  },
  computed: {
    ...mapGetters({
      returnableListSrc: 'getReturnableListFromProductReturn',
      returnTypes: 'getReturnTypes',
      returnOutTypeId: 'getReturnOutTypeId',
      returnOptions: 'getReturnTypesForSelectric',
      companys: 'getCompany',
      products: 'getProduct',
      units: 'getUnitCodes',
      selectedIndex: 'getSelectedIndexFromProductReturn',
      managementData: 'getManagementDataFromProductReturn',
      searchText: 'getSearchTextFromProductReturn',
      companySearchText: 'getCompanySearchTextFromProductReturn',
      start_date: 'getStartDateFromProductReturn',
      end_date: 'getEndDateFromProductReturn',
    }),
    returnableList() {
      if (this.returnableListSrc.length < 1 || this.products.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        return this.lodash
          .clonedeep(this.returnableListSrc)
          .filter(
            x =>
              (this.findInfoFromId(this.products, x.product_id).name.includes(
                this.searchText,
              ) ||
                Hangul.d(
                  this.findInfoFromId(this.products, x.product_id).name,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.searchText)) &&
              (this.findInfoFromId(this.companys, x.company_id).name.includes(
                this.companySearchText,
              ) ||
                Hangul.d(
                  this.findInfoFromId(this.companys, x.company_id).name,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.companySearchText)) &&
              (this.findInfoFromId(
                this.products,
                x.product_id,
              ).standard.includes(this.standardText) ||
                Hangul.d(
                  this.findInfoFromId(this.products, x.product_id).standard,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.standardText)),
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      }
    },
    checkValid() {
      if (this.managementData == null) {
        return false;
      } else {
        if (
          Number(
            Number(
              String(this.managementData.quantity).replace(/,/gi, ''),
            ).toFixed(6),
          ) >
          Number(
            Number(
              String(this.managementData.curr_stock).replace(/,/gi, ''),
            ).toFixed(6),
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    checkTotalCostValid() {
      let calTotalCost;

      if (this.managementData != null) {
        if (
          this.managementData.product_in_out_type_id == this.returnOutTypeId
        ) {
          calTotalCost = this.calSupplyTax(
            this.managementData.vat_id,
            this.managementData.tax_id,
            this.$makeNumber(this.managementData.quantity),
            this.$makeNumber(this.managementData.cost),
          ).total;
        } else {
          calTotalCost = 0;
        }
        if (calTotalCost <= 2147483647) {
          return true;
        } else {
          return false;
        }
      } else return true;
    },
  },
  methods: {
    typing(e) {
      this.$store.commit('setSearchTextToProductReturn', e.target.value);
      this.$store.commit('setManagementDataToProductReturn', null);
      this.$store.commit('setSelectedIndexToProductReturn', -1);
    },
    typingStandard(e) {
      this.standardText = e.target.value.trim();
      this.selectRow(-1);
    },
    typingCompany(e) {
      this.$store.commit('setCompanySearchTextToProductReturn', e.target.value);
      this.$store.commit('setManagementDataToProductReturn', null);
      this.$store.commit('setSelectedIndexToProductReturn', -1);
    },

    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToProductReturn', e.target.value);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_RETURNABLE_PRODUCT_LIST_DATE',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 기록',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToProductReturn', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToProductReturn', e.target.value);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_RETURNABLE_PRODUCT_LIST_DATE',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '매출 기록',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToProductReturn', yyyymmdd(new Date()));
      }
    },
    submitForm() {
      if (this.checkValid && this.checkTotalCostValid) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.standard = this.findInfoFromId(
          this.products,
          payload.product_id,
        ).standard;

        let date = new Date();
        payload.input_date = yyyymmdd(date);
        payload.delivery_date = yyyymmdd(date);
        payload.completed = true;
        payload.box_unit_id = null;
        payload.cost = this.$makeNumber(
          this.$makeNumber(payload.cost).toFixed(6),
        );
        payload.quantity = this.$makeNumber(
          this.$makeNumber(payload.quantity).toFixed(6),
        );
        payload.supply_value = this.calSupplyTax(
          payload.vat_id,
          payload.tax_id,
          payload.quantity,
          payload.cost,
        ).supply;
        payload.tax = this.calSupplyTax(
          payload.vat_id,
          payload.tax_id,
          payload.quantity,
          payload.cost,
        ).tax;
        payload.total_cost = this.calSupplyTax(
          payload.vat_id,
          payload.tax_id,
          payload.quantity,
          payload.cost,
        ).total;

        this.showSpinner();
        this.$store
          .dispatch('INSERT_PRODUCT_RETURN', payload)
          .then(async () => {
            this.openOneButtonModal('반품성공', '반품 처리하였습니다.');
            await this.FETCH_WITH_PAYLOAD(
              'FETCH_RETURNABLE_PRODUCT_LIST_DATE',
              {
                start: this.start_date,
                end: this.end_date,
              },
              '매출 기록',
            );
            this.$store.commit('setManagementDataToProductReturn', null);
            this.$store.commit('setSelectedIndexToProductReturn', -1);
            this.selectRow(-1);
          })
          .catch(() => {
            this.openOneButtonModal(
              '반품실패',
              '반품 등록 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (!this.checkValid) {
        this.openOneButtonModal(
          '반품등록 불가',
          '납품수량 이상 반품받을 수 없습니다.',
        );
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '반환액 초과',
          '1회 반환 최대 금액은<br />20억 이상 반환할 수 없습니다.',
        );
      }
    },
    selectRow(index) {
      this.$store.commit('setSelectedIndexToProductReturn', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToProductReturn', null);
      } else {
        let manageData = this.lodash.clonedeep(this.returnableList[index]);
        manageData['product_in_out_type_id'] = this.lodash.clonedeep(
          this.returnOutTypeId,
        );
        manageData['detail'] = '';
        manageData['curr_stock'] = this.lodash.clonedeep(manageData.quantity);
        this.$store.commit('setManagementDataToProductReturn', manageData);
      }
    },
  },
  async created() {
    if (this.returnTypes.length < 1) {
      await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT');
    }

    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToProductReturn', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToProductReturn', yyyymmdd(date2));
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_RETURNABLE_PRODUCT_LIST_DATE',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '매출 기록',
    );
  },
};
</script>

<style lang="scss" scoped></style>
